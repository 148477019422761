<template>
	<main>
		<div class="tabs">
			<Tabs
				:name="`job-${job && job._id}`"
				:reset-tabs="true"
				:align-center="true"
				@changed="activateCompanyPage"
			>
				<Tab id="job" name="Job">
					<JobDetail
						v-if="job"
						class="h-max container max-w-5xl px-0"
						:job="job"
						:logged-in="loggedIn"
						:job-is-saved="relation && matchesStore.getStatusOfRelation(relation) === 'saved'"
						single-job-view
						show-save-button
						is-pwa
						:show-overlay="false"
						:show-share-button="false"
						:gnc-calculation="gncCalculation"
						:user-image="userImage"
						:show-education="showEducation"
						@save-job="saveJob(relation)"
						@send-job="shareJob(job)"
						@setup-jobalarm="openJobAlarmPage"
						@send-gnc="calcGnc"
						@go-to-jobsearch="openJobsearch"
						@acts-invite="activeSourcingInvite"
						@show-map-dropdown="trackCommute('dropdown')"
						@show-map-image="trackCommute('image')"
						@show-usp="trackUSP"
					/>
				</Tab>
				<Tab id="company-scroll-container" name="Unternehmen">
					<CompanyPageWrapper
						v-if="job && job.company._id"
						:company-id="job.company._id"
						:load-instantly="false"
						@activate-jobalarm="activateJobAlarm"
					/>
				</Tab>
			</Tabs>
		</div>
		<div
			v-if="swipeFcts && $page && $page.isOpen && showSwipeIcons"
			class="swipe-actions flex fixed inset-x-0 bottom-0 justify-center pb-1 z-20"
		>
			<button
				class="bg-color-purple hover:bg-color-purple-hover"
				@click="EventBus.$emit('swipe-dislike')"
			>
				<HokIcon class="mx-auto" name="icon:swipe-left" color="white" />
			</button>

			<button class="bg-color-blue hover:bg-color-blue-hover" @click="EventBus.$emit('swipe-like')">
				<HokIcon class="mx-auto" name="icon:swipe-right" color="white" />
			</button>
		</div>
		<!--confirm privacy-->
		<HokModal :adaptive="!!$isMobile.any" name="accept-privacy-application">
			<div class="mb-4">
				<h3 class="text-center">Datenschutzbestimmungen</h3>
				<HokCheckbox
					id="privacy_user_application_modal"
					v-model="acceptedPrivacy"
					name="privacy_user_application"
				>
					Hiermit stimme ich dem Versand meiner Daten an Firmen, bei denen ich mich bewerbe, zu.
					<template #more>
						<HokLink to="/privacy#user-application" target="_blank" class="text-sm block">
							Datenschutzerklärung & Widerrufsrecht
						</HokLink>
					</template>
				</HokCheckbox>
			</div>
			<div class="flex justify-center">
				<HokButton
					fullwidth="mobile"
					class="mb-4"
					color="main"
					:disabled="!acceptedPrivacy"
					@click="applyDSGVO"
				>
					Fortfahren
				</HokButton>
			</div>
		</HokModal>

		<HokModal name="more-info-modal" @vue:mounted="openMoreInfo">
			<h4 class="container">Mehr Informationen anfordern</h4>
			<MoreInfoModal
				@request-more-info="
					(messagingTag, message) => {
						if (job && job.activeSourcingConversationId) {
							requestMoreInfo(
								messagingTag,
								message,
								{ type: 'job', obj: job },
								// todo: REFACTOR!! use job.selectedCandidate as object instead (and name it job.selectedCandidate.matchId)
								job.activeSourcingConversationId
							);
						}
					}
				"
			/>
		</HokModal>
	</main>
</template>

<script lang="ts">
import Tabs from '@hokify/shared-components-nuxt3/lib/components/Tabs.vue';
import Tab from '@hokify/shared-components-nuxt3/lib/components/Tab.vue';
import JobDetail from '@hokify/shared-components-nuxt3/lib/components/JobDetail.vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import type {
	APIMatchRelation,
	APIObjectType,
	APITypeObjectId,
	IAPIJobFilter,
	IAPIJobForUser,
	IAPILoginUser,
	MessagingTags
} from '@hokify/common';
import { isNotAuthenticatedError } from '@hokify/pwa-core-nuxt3/errors/NotAuthenticatedError';
import { PrivacyType } from '@hokify/shared-components-nuxt3/lib/types/privacyType';
import HokCheckbox from '@hokify/shared-components-nuxt3/lib/components/HokCheckbox.vue';
import { shareJob } from '@hokify/pwa-core-nuxt3/helpers/sharejob';
import { insidePages } from '@hokify/shared-components-nuxt3/lib/plugins/insidepage/insidePageService';
import { mapStores } from 'pinia';
import { markRaw, defineAsyncComponent } from 'vue';
import { defineNuxtComponent, definePageMeta, useRoute, useSeoMeta, createError } from '#imports';
import { useGncStore } from '@/stores/gnc';
import MoreInfoModal from '@/components/MoreInfoModal.vue';
import CompanyPageWrapper from '@/components/user/CompanyPageWrapper.vue';
import { useMatchesStore } from '@/stores/matches';
import { useMessagesStore } from '@/stores/messages';
import { useRelationsStore } from '@/stores/relations';
import { useUserProfileStore } from '@/stores/user-profile';
import { useLoginStore } from '@/stores/login';
import { useUserJobFilterStore } from '@/stores/user-job-filter';
import { useUserJobAlarmStore } from '@/stores/user-job-alarm';
import { useValuesStore } from '@/stores/values';

export default defineNuxtComponent({
	name: 'JobDetailOverview',
	components: {
		JobDetail,
		Tabs,
		Tab,
		CompanyPageWrapper,
		HokCheckbox,
		MoreInfoModal
	},
	setup() {
		definePageMeta({
			layout: 'user',
			path: '/pwa/job/:jobNr?',
			middleware: ['auth']
		});
	},
	async asyncData({ $pinia }) {
		const { params, query } = useRoute();
		let jobNr;

		try {
			const relationsStore = useRelationsStore($pinia);
			const valuesStore = useValuesStore($pinia);

			if (params?.jobNr) {
				jobNr = params.jobNr;
			} else if (relationsStore.numberOrId) {
				jobNr = relationsStore.numberOrId.idOrNr;
			}
			const [job, jobFields] = await Promise.all([
				relationsStore.getJob({ jobNr }),
				valuesStore.getJobFields()
			]);

			let showEducation = false;
			if (query.showEducation === 'true') {
				showEducation = true;
			}

			return { job, jobFields, showEducation };
		} catch (err: any) {
			if (err.response) {
				throw createError({
					statusCode: err.response._data.status,
					statusMessage:
						err.response._data && err.response._data.code
							? `${err.response._data.code}: ${err.response._data.msg || err.response._data.message}`
							: `Es ist ein Fehler aufgetreten${
									err.response._data ? ` (${JSON.stringify(err.response._data)})` : ''
								}`,
					fatal: true
				});
			} else {
				console.error(err);
			}
		}
	},
	data() {
		const gncCalculation = undefined as {} | undefined;
		const job = undefined as undefined | IAPIJobForUser;
		const observer = undefined as undefined | IntersectionObserver;
		const swipeFcts = false;
		const showSwipeIcons = false;
		const jobFields = undefined as any;
		const privacyError = false;
		const acceptedPrivacy = false;
		const jobTrackingObject = undefined as any;
		const showEducation = false;

		return {
			swipeFcts,
			observer,
			job,
			shareJob,
			showSwipeIcons,
			jobFields,
			gncCalculation,
			privacyError,
			acceptedPrivacy,
			jobTrackingObject,
			showEducation,
			EventBus
		};
	},
	created() {
		const title = `${
			(this.job && this.job.name ? `Deine Bewerbung als ${this.job.name}` : 'Deine Bewerbung') +
			(this.job && this.job.company ? ` bei ${this.job.company.name}` : '')
		} | hokify`;
		const description = `Job zu vergeben: " ${(this.job && this.job.name) || ''}${
			(this.job && this.job.company && this.job.company.name && ` bei${this.job.company.name}`) ||
			''
		}. Jetzt bewerben und noch heute eine Antwort erhalten. Weitere Stellenangebote bei hokify.`;
		const image = this.job?.images?.large || '';
		const webUrl = this.job?.webUrl || '';
		useSeoMeta({
			title,
			description,
			ogTitle: title,
			ogImage: image,
			ogDescription: description,
			ogUrl: webUrl
		});
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.userProfileStore?.obj;
		},
		loggedIn() {
			return this.loginStore.loggedIn;
		},
		relation(): APIMatchRelation | undefined {
			if (this.job) {
				return {
					type: 'job',
					obj: this.job
				};
			}
			return undefined;
		},
		userImage(): string | undefined {
			return this.userProfileStore.obj?.images?.small;
		},
		acceptedPrivacyApplication(): boolean {
			return !!this.userProfileStore.obj?.privacy?.user_application;
		},
		getJobFilter(): IAPIJobFilter | undefined {
			return this.userProfileStore?.obj?.jobFilter;
		},
		...mapStores(
			useGncStore,
			useMatchesStore,
			useMessagesStore,
			useRelationsStore,
			useUserProfileStore,
			useLoginStore,
			useUserJobFilterStore,
			useUserJobAlarmStore,
			useValuesStore
		)
	},
	mounted() {
		this.$trackUserEvent?.('detail_view_enlarge', {
			job: { jobNr: this.job?.jobNr, name: this.job?.name, _id: this.job?._id }
		});
		this.registerIntersectionObserver();
	},
	beforeUnmount() {
		this.EventBus.$off('set-job-filter');
		this.EventBus.$off('request-more-info');
	},
	methods: {
		openMoreInfo() {
			if (this.$route.query?.moreInfo === 'true') {
				this.activeSourcingInvite('more-info');
			}
		},
		registerIntersectionObserver() {
			this.observer = new IntersectionObserver(this.isVisible);
			this.observeElements();
		},
		observeElements() {
			const element =
				document.querySelector('#jobdetail-apply-mobile') ||
				document.querySelector('#jobdetail-apply') ||
				document.querySelector('#jobdetail-contact-mobile');
			if (element) {
				this.observer?.observe(element);
			}
		},
		isVisible(entries) {
			this.showSwipeIcons = !entries.some(entry => entry.isIntersecting);
		},
		activateCompanyPage({ tab }) {
			if (tab.id === 'company-scroll-container') {
				// make sure to update carousel when switching to a company page, otherwise width calculation would be wrong
				this.EventBus.$emit('page-close-observed');
				// make sure to load company details
				this.EventBus.$emit('load-company-details');
				// make sure to hide apply buttons
				this.swipeFcts = false;
			} else {
				// make sure to show apply buttons
				this.swipeFcts = true;
			}
		},
		async activateJobAlarm() {
			try {
				await this.userJobAlarmStore.setJobAlarm({
					filters: [`company-${this.job?.company.alias}`]
				});

				this.$snack.success({
					text: `Jobalarm für ${this.job?.company.name} erstellt.`,
					button: 'Bearbeiten',
					action: () => {
						this.$router.push({ path: '/pwa/joblist/jobalarm' });
					}
				});
				this.$trackUserEvent?.('activate_jobalarm_completed', { pageElement: 'job-detail' });
			} catch (err: any) {
				if (isNotAuthenticatedError(err)) {
					this.$snack.danger({
						text: 'Du musst angemeldet sein, um einen Jobalarm anlegen zu können.',
						button: 'Anmelden',
						action: () => {
							this.$router.push({ path: '/pwa/login' });
						}
					});
					return;
				}
				this.$nuxt.$errorHandler(
					err,
					'Beim setzen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
				);
			}
		},
		async saveJob(matchRelation) {
			try {
				let { name } = matchRelation.obj;
				if (name.trim().length > 30) {
					name = `${name.trim().slice(0, 30)}...`;
				}
				if (this.loggedIn) {
					if (matchRelation && this.matchesStore.getStatusOfRelation(matchRelation) !== 'saved') {
						await this.relationsStore.saveRelation(matchRelation);
						await this.$trackUserEvent?.('save_job', {
							item_id: matchRelation.obj._id,
							item_type: 'job'
						});

						this.$snack.success({
							text: `Der  Job "${name}" wurde gespeichert!`
						});
					} else if (
						matchRelation &&
						this.matchesStore.getStatusOfRelation(matchRelation) === 'saved'
					) {
						const match = this.matchesStore.getMatchByRelation(matchRelation);
						if (!match) {
							this.$snack.danger({
								text: `Der gespeicherte Job "${name}" konnte nicht gelöscht werden, bitte versuche es später erneut.`
							});
							throw new Error(`match not found for relation ${matchRelation?.obj?._id}`);
						}

						await this.matchesStore.deleteMatch({ match });
						this.$snack.success({
							text: `Der gespeicherte Job "${name}" wurde gelöscht!`
						});
					}
				} else {
					this.$snack.danger({
						text: 'Du musst angemeldet sein, um einen Job speichern zu können.',
						button: 'Anmelden',
						action: () => {
							this.$deepLinkRouter.handleUrl(`/save/${this.job?.jobNr}`);
						}
					});
				}
			} catch (err: any) {
				this.$nuxt.$errorHandler(
					err,
					'Beim Speichern des Jobs ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.'
				);
			}
		},
		async openJobAlarmPage() {
			if (!this.jobFields.length) {
				this.jobFields = await this.valuesStore.getJobFields();
			}

			const jobAlarmPage = markRaw(
				defineAsyncComponent(
					() => import('@hokify/shared-components-nuxt3/lib/pages/jobAlarmPage.vue')
				)
			);
			try {
				await this.$page.push(
					jobAlarmPage,
					{
						selected: this.getJobFilter,
						jobFields: this.jobFields,
						getPossibleFilter: this.userJobFilterStore.getPossibleFilter,
						user: this.user
					},
					{
						pageTitle: 'Jobalarm einstellen',
						name: 'set-job-alarm',
						done: () => {
							this.EventBus.$off('set-job-filter');
						}
					}
				);
				this.EventBus.$on('set-job-filter', async jobFilterObj => {
					try {
						await this.userJobAlarmStore.setJobAlarm(jobFilterObj);
						this.$router.push({ path: '/pwa/joblist/jobalarm#jobalarm' });
						this.$snack.success({
							title: 'Jobalarm erstellt!',
							text: 'Du bekommst ab jetzt eine Mitteilung, wenn wir einen neuen Job für dich haben, der zu deinem Filter passt.'
						});
						this.$trackUserEvent?.('activate_jobalarm_completed', {
							pageElement: 'job-alarm-list'
						});
					} catch (err: any) {
						console.error('setJobalarm failed', err);
						this.$nuxt.$errorHandler(
							err,
							'Beim setzen deines Jobalarms ist ein Fehler aufgetreten. Bitte versuch es später noch einmal.'
						);
					}
				});
			} catch (err) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async calcGnc(gnc) {
			try {
				this.gncCalculation = await this.gncStore.sendGnc({
					...gnc,
					locationUrl: 'job-detail-page'
				});
				this.$trackUserEvent?.('start_calculation', {});
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		openJobsearch() {
			this.$router.push('/pwa');
			this.$trackUserEvent?.('click_redirect', { url: '/pwa' });
		},
		async activeSourcingInvite(interaction: 'accepted' | 'declined' | 'more-info') {
			if (!this.acceptedPrivacyApplication) {
				this.$modal.show('accept-privacy-application');
				return;
			}
			try {
				// TODO use selectedCandidate as OBJECT
				if (this.job) {
					await this.processInviteInteraction(
						interaction,
						// this.job.selectedCandidate.matchId
						this.job?.activeSourcingConversationId,
						this.job
					);
				}
				if (interaction === 'declined') {
					this.job = await this.relationsStore.getJob({ jobNr: this.job?.jobNr, force: true });
				}
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		},
		async processInviteInteraction(
			interaction: 'accepted' | 'declined' | 'more-info',
			matchId: APITypeObjectId<APIObjectType.Match> | undefined,
			job: IAPIJobForUser
		) {
			if (!matchId) {
				throw new Error('no match id provided');
			}
			this.jobTrackingObject = {
				name: job?.name,
				jobNr: job?.jobNr,
				_id: job?._id,
				jobfields: job?.fields?.map(f => f.name),
				type: job?.type,
				priorize: job?.internal.trackingValue || 1,
				country: job?.location?.countryCode?.replace(/\W/g, '').toUpperCase(),
				city: job?.location?.city || undefined
			};

			if (interaction !== 'declined' && job.activeSourcingApplicationTarget === 'chat') {
				this.$trackUserEvent?.('start_application', {
					matchId,
					job: this.jobTrackingObject,
					category: 'app-jobdetail',
					relationId: job._id,
					item_type: 'job',
					is_logged_in: this.loginStore.loggedIn ? 1 : 0,
					application_type: 'active-sourcing'
				});
			}

			this.$trackUserEvent?.('active_sourcing_reverse_apply', { interaction });
			if (interaction === 'accepted') {
				try {
					switch (job.activeSourcingApplicationTarget) {
						case 'chat':
							await this.relationsStore.applyToRelation({
								relation: { type: 'job', obj: job }
							});
							this.$trackUserEvent?.('send_application', {
								matchId,
								job: this.jobTrackingObject,
								relationId: job?._id,
								item_type: 'job',
								category: 'app-jobdetail',
								application_type: 'active-sourcing'
							});
							this.$router.push(`/pwa/match/${matchId}?reverseApply=accepted`);
							break;
						case 'application':
							this.$router.push(`/apply/${job.jobNr}`);
							break;
						default:
							throw new Error(
								`invalid activeSourcingApplicationTarget: ${job.activeSourcingApplicationTarget}`
							);
					}
				} catch (err: any) {
					if (err.response?.data?.code === 'PRIVACY_NOT_ACCEPTED') {
						this.$modal.show('accept-privacy-application');
					}
				}
			} else if (interaction === 'declined') {
				if (insidePages?.pages?.length > 0) {
					this.EventBus.$emit('swipe-dislike');
				} else {
					try {
						await this.relationsStore.discardRelation({
							type: 'matchrelation',
							relation: { type: 'job', obj: job }
						});
						this.$snack.success({
							text: 'Das Unternehmen wurde über dein Nicht-Interesse informiert.'
						});
					} catch (err) {
						throw new Error('Entscheidung konnte nicht gespeichert werden!');
					}
				}
			} else if (interaction === 'more-info') {
				if (this.$isMobile.any) {
					const modal = markRaw(
						defineAsyncComponent(() => import('@/components/MoreInfoModal.vue'))
					);
					try {
						await this.$page.push(modal, this.$props, {
							modalHeading: 'Mehr Informationen anfordern',
							mode: 'modal',
							name: 'hok-moreinfo-modal',
							done: () => {
								this.EventBus.$emit('done-more-info');
								this.EventBus.$off('request-more-info');
							}
						});
						this.EventBus.$on(
							'request-more-info',
							async (obj: { req: MessagingTags; msg: string }) => {
								await this.requestMoreInfo(obj.req, obj.msg, { type: 'job', obj: job }, matchId);
							}
						);
					} catch (err) {
						this.$nuxt.$errorHandler(err);
					}
				} else {
					this.$modal.show('more-info-modal');
				}
			} else {
				throw new Error('interaction type missing');
			}
		},
		async requestMoreInfo(
			messagingTag: MessagingTags,
			message: string,
			relation: APIMatchRelation,
			matchId: APITypeObjectId<APIObjectType.Match>
		) {
			try {
				// we cannot Promise.all these requests because there needs to be a specific order in chat messages
				await this.relationsStore.applyToRelation({ relation });
				await this.messagesStore.sendMsg({
					conversationTypeId: matchId,
					conversationType: 0,
					message,
					messagingTag
				});
				this.$trackUserEvent?.('send_application', {
					matchId,
					job: this.jobTrackingObject,
					relationId: this.jobTrackingObject._id,
					item_type: 'job',
					category: 'app-jobdetail',
					application_type: 'active-sourcing'
				});
				this.$router.push(`/pwa/match/${matchId}?reverseApply=moreInfo`);
			} catch (err: any) {
				this.$nuxt.$errorHandler(err, 'Anfrage konnte nicht versendet werden');
			}
		},
		trackCommute(type: 'dropdown' | 'image') {
			this.$trackUserEvent?.('click_commute_element', {
				pageElement: type
			});
		},
		trackUSP() {
			this.$trackUserEvent?.('click_education', {});
		},
		async applyDSGVO() {
			try {
				await this.userProfileStore.acceptPrivacy(PrivacyType.UserApplication);
				this.$modal.hide('accept-privacy-application');
			} catch (err: any) {
				this.$nuxt.$errorHandler(err);
			}
		}
	},
	props: {
		jobNr: { type: Number, default: () => {} }
	}
});
</script>

<style scoped lang="scss">
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.bounce-enter-active {
	animation: bounce-in 0.5s;
}

@keyframes bounce-in {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1.5);
	}
	100% {
		transform: scale(1);
	}
}

.swipe-actions {
	button {
		margin: 5px;
		width: 64px;
		height: 64px;
		text-align: center;
		outline: none;
		border-radius: 50%;
		transition: color 600ms ease;
	}
}
</style>
